import { Stack, Text, Image, Center, Box, Button, useBreakpointValue, ModalCloseButton } from "@chakra-ui/react";
import React from "react";
import { useButtonProps, useConfigMap, useGetViewConfig } from "hooks";
import { useConversationContext } from "screens/thread/ConversationContext";
import { ONBOARDING_MODEL_HEIGHT } from "./OnboardingModal";
import { useDispatch } from "react-redux";
import { updateTypedUserPreference } from "state/userPreference/operations";
import { useCopyValue } from "hooks/useCopies";

export const OnboardingLearnMoreStep = () => {
  const configMap = useConfigMap();
  const homeOnboardingSteps = useGetViewConfig("onBoardingSteps", "home", configMap);
  const commonButtonProps = useButtonProps("md", "cta");
  const { isOnboardingModalOpen, onOnboardingModalClose } = useConversationContext();
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: "md", ssr: false });
  const dispatch = useDispatch();
  const copyOnboardingTitle = useCopyValue("copy_onboarding_modal_step3_title");
  const copyOnboardingSubTitle = useCopyValue("copy_onboarding_modal_step3_subtitle");

  return (
    <Stack justifyContent="space-between" spacing="0" height={ONBOARDING_MODEL_HEIGHT}>
      <ModalCloseButton
        color={"gray.300"}
        onClick={() => {
          dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
          isOnboardingModalOpen && onOnboardingModalClose();
        }}
      />
      <Stack spacing="0" height="100%" justifyContent={"space-between"}>
        <Stack height="100%" spacing="1rem" bgColor={"primary.default"} px={isMobile ? "1rem" : "2rem"} pt="2rem" color="white">
          <Center>
            <Text fontWeight={"semibold"} fontSize="xl">
              {copyOnboardingTitle}
            </Text>
          </Center>
          <Text fontSize="md" width="100%" textAlign="center">
            {copyOnboardingSubTitle}
          </Text>
        </Stack>
        <Center>
          <Image
            cursor={"pointer"}
            borderRadius={"md"}
            boxShadow={"lg"}
            position={"absolute"}
            top="10rem"
            src={homeOnboardingSteps && homeOnboardingSteps[0].thumbnailSource}
            onClick={() => window.open(homeOnboardingSteps && homeOnboardingSteps[0].url, "_blank")}
            height="12rem"
            transition="transform 0.3s ease-in-out"
            _hover={{
              transform: "scale(1.05)",
            }}
          />
        </Center>
        <Box height="100%" display={"flex"} alignItems="center" justifyContent="center">
          <Button
            {...commonButtonProps}
            mt="2rem"
            fontWeight={"normal"}
            bgColor={"#81c34b"}
            borderColor={"#81c34b"}
            width="12rem"
            borderRadius={"full"}
            id={`ch-close-onboarding-learn-more`}
            aria-label="submit"
            onClick={() => {
              dispatch(updateTypedUserPreference({ preferenceKey: "ui_onboarding_completed", value: true }));
              isOnboardingModalOpen && onOnboardingModalClose();
              // window.open(homeOnboardingSteps && homeOnboardingSteps[0].url, "_blank");
            }}>
            Complete Onboarding
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};
